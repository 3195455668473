import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../../components/layout';
import Rule from '../../components/rule';
import Section from '../../components/section';

export const query = graphql`
  query QueryPolicy($id: String) {
    mdx(id: { eq: $id }, fileAbsolutePath: { regex: "/src/mdx/policies/" }) {
      frontmatter {
        title
        description
        mood_image {
          publicURL
        }
      }
      body
      excerpt
      slug
    }
  }
`;

const PolicyPage = ({ data }) => {
  if (!data.mdx) {
    // for blogs & products
    return null;
  }

  const { frontmatter, body, slug } = data.mdx;

  const {
    title,
    description,
    // eslint-disable-next-line camelcase
    mood_image,
  } = frontmatter;

  const htmlHeadTitle = `${title} • Legal / Policies`;

  return (
    <>
      <GatsbySeo
        language="en"
        title={htmlHeadTitle}
        description={description || data.excerpt}
        openGraph={{
          url: `https://www.dsapps.dev/policies/${slug}`,
        }}
      />
      <Layout pageTitle={htmlHeadTitle}>
        <Section className="bg-purple-100 dark:bg-blue-900">
          <section className=" body-font text-gray-600 dark:text-gray-200 animate-underline-anchors">
            <div className="container flex flex-col md:flex-row">
              <div className="flex flex-col mb-16 md:w-2/3 lg:pr-24 md:pr-16 md:items-start md:text-left md:mb-0">
                <h2 className="mb-1 font-sans text-xs font-medium tracking-widest text-blue-500 title-font">
                  <Link to="/policies/" className="uppercase animate-underline">
                    Policies
                  </Link>
                </h2>
                <Rule />

                <h1 className="my-4 text-2xl text-gray-900 dark:text-gray-100 sm:text-3xl title-font">
                  {title}
                </h1>

                <div className="animate-underline-anchors content-body">
                  <MDXRenderer>{body}</MDXRenderer>
                </div>
              </div>
              <div className="items-center w-5/6 md:mt-10 md:w-1/3">
                {mood_image && mood_image.publicURL ? (
                  <img src={mood_image.publicURL} alt="*" />
                ) : null}
              </div>
            </div>
          </section>
        </Section>
      </Layout>
    </>
  );
};

export default PolicyPage;
